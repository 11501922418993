export var currentPort = navigator.serial;
export var currentPortStatus = 1;
export var currentPortDevInfo;

/*
currentPortStatus
1: no detected
2: disconnect
3: connect
4: open
*/

export async function connect(baudRate) {
  currentPort = await navigator.serial.requestPort()
    .then((res) => {return res;})
    .catch((error) => {});

  await currentPort?.open({ baudRate: baudRate || 9600 })
    .then((res) => {currentPortStatus = 4})
    .catch((error) => {});
  
  await getDeviceInfo();
}


export async function getDeviceInfo() {
  try {
    var portInfo = currentPort?.getInfo();
    currentPortDevInfo = Object.keys(portInfo).reduce((res, key)=> {return res + key+'= '+portInfo[key]+' '}, '' );
  } 
  catch (error) {}
}

export async function listen(output) {
  if (!('serial' in navigator)) {
    currentPortStatus = 0;
    output();
  }
  
  navigator.serial.addEventListener('disconnect', (e) => {
    currentPortStatus = 2;
    output();
  })

  navigator.serial.addEventListener('connect', (e) => {
    currentPortStatus = 3;
    currentPort = e.target;
    output();
  });
}

export async function read(output) {
  let decoder = new TextDecoder();
  while (currentPort?.readable) {
    const reader = currentPort.readable.getReader();
    try {
      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        let valueStr = decoder.decode(new Uint8Array(value));
        output(valueStr);
      }
    } 
    catch (error) {}
    finally {output(''); reader.releaseLock();}
  }
}

export async function write(value) {
  const writer = currentPort?.writable.getWriter();

  await writer?.write(new TextEncoder().encode(value));
  writer.releaseLock();
}

