import * as React from 'react';
import { Card, FormHeader, Table } from '../../components';
import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';

interface IProps { 
  history?: any;
  parentId?: number; //for nested list, need parent id to filling default value (eg. transaction Id)
  caption?: string;
  captionColor?: string;
  captionThin?: boolean;
  endPointFilter?: string;
  model: any;
  header?: any;
  url?: string;
  getDataSet?: (data: any, page?: any) => void;
  page?: number;
  pageSize?: number;
  searchField?: string;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  actions?: any;
  disableView?: boolean;
  disableCaptionSuffix?: boolean;
  disableBackButton?: boolean;
  disableCard? : boolean;
}

interface IState {
  dataSet: Array<any>;
  header: Array<any>;
  endPointFilter: string;
  parentId: number;
  page: number;
  pageSize: number;
  pageCount: number;
  actions: any;
  searchText: string;
  isSearch: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BaseList extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.props.model,
      header: this.props.header || this.props.model.columns,
      endPointFilter: this.props.endPointFilter || '',
      parentId: this.props.parentId || 0,
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      actions: this.actions(),
      searchText: '',
      isSearch: this.props.model.columns?.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'name'))>=0,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onEndTypingSearch = this.onEndTypingSearch.bind(this);
  }
  
  private historyState: any = this.props.history?.location.state;
  private isLoading: boolean = false;

  private loadData (page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});
    
    const paginationPage = '&pagination[page]='+page;
    const paginationPageSize = '&pagination[pageSize]='+this.state.pageSize;
    const search = searchText? '&filters['+(this.props.searchField || 'name')+'][$contains]='+searchText : paginationPage;
    
    ApiService.getAll<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*')+search+paginationPageSize).then((rp) => {
      if (rp.Status) {
        const pageCount = Math.ceil((rp.Meta?.pagination?.total||1)/this.state.pageSize);
        const data = rp.Data;
        var dataSet = new Array<typeof this.props.model>();
        (data || []).forEach((dataRow: any) => {dataSet.push(new this.props.model(dataRow));});
        this.setState({ dataSet: dataSet, pageCount:pageCount, isReady: true });
        this.props.getDataSet?.(dataSet, rp.Meta.pagination);
      } 
      else { this.setState({ isReady: true, isError: true }); }
      this.isLoading = false;
    });
  }

  private actions = (id?: number) => {
    var url = this.props.url + '/';
    const actionList = [
      {id: 'create', caption: 'Create', icon:'add', url: url+'create', state: {id: this.props.parentId}}, 
      {id: 'copy', caption: 'Duplicate', icon:'queue_outlined', url: id && url+'copy', state: {id: id}}, 
      {id: 'view', caption: 'View', icon:'segment', url: id && url+'view', state: {id: id}}, 
      {id: 'edit', caption: 'Edit', icon:'drive_file_rename_outline', url: id && url+'edit', state: {id: id}}, 
      {id: 'status', caption: 'Change Status', icon:'verified_user', url: id && url+'status', state: {id: id}}, 
      {id: 'preview', caption: 'Preview', icon:'find_in_page', url: id && this.props.actions && url+'preview', state: {id: id, parentId: this.state?.parentId}}, 
      {id: 'share', caption: 'Share', icon:'share', url: id && url+'share', state: {id: id}}, 
      {id: 'delete', caption: 'Delete', icon:'delete', color:'red', url: id && url+'delete', state: {id: id}}, 
    ];

    const actionDefault = (!this.props.actions || this.props.actions?.includes('...'))? ['create', 'copy', 'view', 'edit', 'delete'] : [];

    return actionList.filter((d: any) => 
      (actionDefault.includes(d.id)  || this.props.actions?.includes(d.id))
    );
  }

  private getDataRow = (data: any) => {
    const actions = this.actions(data.id);
    if (actions.length===1) this.props.history?.push(actions[0].url, actions[0].state)
    this.setState({ actions: actions});
  }

  private onChangePage (e: React.ChangeEvent<unknown>, value: number) {
    this.props.history?.replace(this.props.history.location.pathname, {...this.props.history.location.state, page : value})
    this.setState({page: value});
    this.loadData(value, this.state.searchText);
    this.props.onChangePage?.(e,value)
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
  }
  
  private onClickSearch() { 
    this.loadData(this.state.page, this.state.searchText);
  }

  private onClickCreate = (data: any) =>{ 
    this.props.history?.push(data.url, data.state)
  }

  private onEndTypingSearch(fieldName?: string, value?: string) {
    this.setState({searchText: value || ''});
    this.loadData(this.state.page, value);
  }

  private table(){
    return <Table history={this.props.history} header={this.state.header} dataSet={this.state.dataSet} getDataRow={this.getDataRow} page={this.state.page} pageCount={this.state.pageCount} actions={this.state.actions} searchText={this.state.searchText} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} onClickCreate={this.onClickCreate} onEndTypingSearch={this.onEndTypingSearch}/>
  }

  public componentDidMount() {
    var page = this.historyState?.page || this.state.page;
    this.loadData(page);
    this.setState({page: page})
  }

  public componentDidUpdate() {
    if (!this.isLoading)
      if (this.props.page && (this.props.page !== this.state.page)) {
        this.setState({page: this.props.page})
        this.loadData(this.props.page, this.state.searchText);
      }
      else if (this.props.endPointFilter && (this.props.endPointFilter !== this.state.endPointFilter)) {
        this.setState({endPointFilter: this.props.endPointFilter})
        this.loadData(1);
      }
  }

  public render(): React.ReactNode {  
    var caption = this.props.caption || this.props.model.caption;
    if (!this.props.disableCaptionSuffix) caption = AppService.strPluralize(caption);
    
    return (
      <>
      {!this.props.disableView &&
      <>
        <FormHeader history={this.props.history} label={caption} color={this.props.captionColor} thin={this.props.captionThin} disableBackButton={this.props.disableBackButton} /> 
        {this.props.disableCard? this.table():
          <Card>{this.table()}</Card>           
        }          
      </>
      }
      </>
    );
  }
}
