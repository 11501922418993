import * as React from 'react';

interface Props {
  id?: string;
  serverUrl: string;
  event: string;
  getDataRow?: (data?: any) => void;
}

export const ServerSentEvent: React.FunctionComponent<Props> = (props) => {;
  
  React.useEffect(() => {
    const sse = new EventSource(props.serverUrl);
    sse.addEventListener(props.event, e => {props.getDataRow?.(JSON.parse(e.data))});
    return () => {sse.close();};
  }, [])

  return (
    <></>
  )
};
